<template>
  <div class="hello">
    <div class="content">
      <img class="top" src="../../src/assets/ic_landingpage@2x.png" alt="" />
      <img class="phone" src="../../src/assets/pic_download@2x.png" alt="" />
      <span>Operating environment: above iOS 10.0.0 | Android 5.0.0</span>
      <div>
        <img
          class="imglsit"
          v-for="(item, index) in imglist"
          :key="index"
          :src="item.url"
          alt=""
          @click="download(item.src)"
        />
      </div>
      <p>
        If link can not be opened, click icon in the upper right corner and
        chese" to open in other browsers" to download APP
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      imglist: [
        {
          url: require("@/assets/ic_download_ios@2x.png"),
          src:'https://apps.apple.com/cn/app/smart-message/id1591569170'
        },
        {
          url: require("@/assets/ic_download_google@2x.png"),
          src:''//下载链接
        },
        {
          url: require("@/assets/ic_download_apk@2x.png"),
          src:''//下载链接
        },
      ],
    };
  },
  methods:{
    download(src){
      window.location.href =src
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  width: 343px;
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  color: #999999;
  line-height: 18px; 
}
.imglsit {
  width: 200px;
  margin: 16px;
}
span {
  width: 180px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #999999;
  line-height: 17px;
  margin-bottom: 20px;
}
.phone {
  width: 182px;
  margin-bottom: 24px;
}
.top {
  width: 208px;
  margin-bottom: 50px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
